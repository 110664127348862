import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Surf Station Fethiye
			</title>
			<meta name={"description"} content={"Hayatınızın Dalgasını Yakalayın!"} />
			<meta property={"og:title"} content={"Ana Sayfa | Surf Station Fethiye"} />
			<meta property={"og:description"} content={"Hayatınızın Dalgasını Yakalayın!"} />
			<meta property={"og:image"} content={"https://solarielbiz.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://solarielbiz.com/images/34623577.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://solarielbiz.com/images/34623577.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://solarielbiz.com/images/34623577.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://solarielbiz.com/images/34623577.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://solarielbiz.com/images/34623577.png"} />
			<meta name={"msapplication-TileImage"} content={"https://solarielbiz.com/images/34623577.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Surf Station Fethiye
				</Text>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 400 28px/1.5 --fontFamily-sansHelvetica"
					color="#50555a"
					padding="0px 100px 0px 100px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					Dalgalara binmeye ve sörf heyecanını yaşamaya hazır mısınız? Fethiye Camp'te hem yeni başlayanlar hem de deneyimli sörfçüler için unutulmaz bir macera sunuyoruz. Misyonumuz, birinci sınıf sörf eğitimi, güvenlik ve daha fazlası için geri gelmenizi sağlayan heyecan verici bir deneyim sağlamaktır.
				</Text>
				<Button
					margin="0px 0 40px 0px"
					padding="14px 35px 14px 35px"
					background="#3f24d8"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
				>
					Bize Ulaşın
				</Button>
			</Box>
			<Image
				src="https://solarielbiz.com/images/1.jpg"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
			/>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-5">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
				align-items="flex-start"
				lg-width="100%"
			>
				<Text
					margin="0px 36px 00px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="30%"
					lg-width="100%"
					lg-margin="24px 0px 30px 0px"
					lg-order="1"
					md-text-align="left"
				>
					Surf Station Fethiye Hakkında
					<br />
					<br />
					Mükemmel dalgalara ve nefes kesici manzaralara sahip el değmemiş bir konumda yer alan Surf Station Fethiye, sörf macerası için ideal bir yerdir. Kampımız, yeni başlayanlardan yıllardır dalgalara binenlere kadar her seviyeden sörfçüye hitap edecek şekilde tasarlanmıştır. Sörfe olan sevgilerini paylaşma konusunda tutkulu olan samimi ve deneyimli eğitmenlerimizle gurur duyuyoruz.
				</Text>
				<Box padding="0px 0px 40% 0px" max-height="400px" overflow="hidden">
					<Image src="https://solarielbiz.com/images/3.jpg" object-fit="cover" display="block" width="100%" />
				</Box>
			</Box>
			<Box
				justify-content="center"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="45%"
				lg-width="100%"
				align-items="flex-start"
			>
				<Box
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					overflow-y="hidden"
					position="relative"
					padding="0px 0px 150% 0px"
					lg-padding="0px 0px 100% 0px"
				>
					<Image
						object-fit="cover"
						display="block"
						top={0}
						lg-right="0px"
						lg-width="100%"
						bottom={0}
						min-height="100%"
						lg-left="-1px"
						src="https://solarielbiz.com/images/2.jpg"
						position="absolute"
						width="100%"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="55%"
				lg-order="1"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="100%" padding="0px 0px 65% 0px" max-height="400px">
					<Image src="https://solarielbiz.com/images/4.jpg" object-fit="cover" display="block" width="100%" />
				</Box>
				<Text
					margin="30px 0px 16px 0px"
					font="--headline3"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Neden Surf Station Fethiyenı Seçmelisiniz?
				</Text>
				<Text
					lg-text-align="center"
					margin="14px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Uzman Eğitmenler: Ekibimiz yılların deneyimine sahip sertifikalı profesyonellerden oluşmaktadır. Hızlı ve güvenli bir şekilde gelişmenize yardımcı olmak için kişiselleştirilmiş koçluk sağlarlar.
					<br />
					<br />
Birinci Sınıf Ekipman: Harika bir sörf deneyimi için ihtiyacınız olan her şeye sahip olmanızı sağlamak için en iyi tahtaları ve ekipmanları sağlıyoruz.
					<br />
					<br />
Çarpıcı Konum: Kampımız, her sörf seansını bir zevk haline getiren tutarlı dalgaları ve güzel çevresi ile bilinen bir noktada yer almaktadır.
					<br />
					<br />
Kapsayıcı Topluluk: Surf Station Fethiyenda, tutkularını paylaşmaya ve birbirlerini desteklemeye hevesli sörfçülerden oluşan sıcak bir topluluk bulacaksınız.

				</Text>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});